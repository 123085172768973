import {formatUnits } from "viem";
import {TokenSymbol} from "@/components/Perps/TokenSymbol";
import React from "react";

export interface Props {
  inputValue: string | undefined;
  setInputValue: (value: string | undefined) => void;
  symbol: string;
  tokenDecimals: number;
  placeholder?: string;
  imageUrl?: string;
  displayTokenSelect?: boolean | undefined;
  title?: any;
  currentBalance?: bigint;
}

export const TokenInput = (
  { inputValue, setInputValue, symbol, tokenDecimals, placeholder = "Amount", imageUrl, displayTokenSelect, title, currentBalance }: Props
) => {
  return (
    <div className="standard-frame flex flex-row gap-2 justify-between py-1 md:py-4 px-2 text-xl">
      <div className="flex flex-col gap-1 justify-center">
        {title && <div className="text-neutral-content text-xs">{title}</div>}
        <input
          type="number"
          className="unstyled-input text-lg md:text-2xl text-white truncate w-[180px]"
          placeholder={placeholder}
          maxLength={7}
          min={0}
          value={inputValue ?? undefined}
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              setInputValue(value);
            } else {
              setInputValue(undefined);
            }
          }}
        />
      </div>
      <div className="flex flex-col items-end gap-2">
        <div></div>
        <TokenSymbol
          symbol={symbol}
          imageUrl={imageUrl}
          displayTokenSelect={displayTokenSelect || false}
        />
        {
          currentBalance !== undefined ?
            <span className="text-xs font-light text-neutral-content">
              Balance: {Number(formatUnits(currentBalance, tokenDecimals)).toLocaleString([], {maximumFractionDigits: 2})}&nbsp;
              <span className="text-call hover:underline cursor-pointer" onClick={() => setInputValue(formatUnits(currentBalance, tokenDecimals))}>Max</span>
            </span> :
            <div></div>
        }
      </div>
    </div>
  )
}