import {SplitLabelValue} from "@/components/SplitLabelValue";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import React, {useEffect, useState} from "react";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {ClosePositionOrder, PerpPositionStatus} from "@/components/Perps/types";
import {EthValue} from "@/components/Perps/EthValue";
import {
  useLimitOrderTransactionSign
} from "@/components/Perps/closePosition/useLimitOrderTransactionSign";
import {Button, ButtonType} from "@/components/Button";
import {formatUnits, parseEther, parseUnits} from "viem";
import {DAY_SECONDS} from "@/util/constants";
import {ClosePositionType} from "@/components/Perps/closePosition/ClosePositionView";
import {
  calculateAmounts,
  calculateLimitPriceFromPnl,
  calculateSlippage
} from "@/components/Perps/closePosition/limitOrderCalculations";
import {FaEthereum} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import classNames from "classnames";
import {WarningPanel} from "@/components/WarningPanel";
import * as Tooltips from "@/util/tooltips";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {BiHelpCircle} from "react-icons/bi";
import {useCancelOrder} from "@/components/Perps/closePosition/useCancelOrderSign";
import {EXECUTION_FEE_USD, isUsdToken} from "@/util/chainConstants";
import {FaCheckCircle, FaChevronDown, FaChevronUp} from "react-icons/fa";
import moment from "moment";
import {getZeroesAfterDecimal} from "@/util/converters";

export type Props = {
  perpPositionStatus: PerpPositionStatus;
  closePositionType: ClosePositionType;
  existingOrder?: ClosePositionOrder | undefined;
  onSave: () => void;
}

const blankValue = <span className="text-sm text-neutral-content">--</span>;

type LimitOrderInputs = {
  limitPrice: string;
  roi: string;
}

const convertPrice = (price: number, isUsd: boolean) => {
  if (isUsd) {
    const newPrice = 1 / Number(price);
    return newPrice.toLocaleString([], { useGrouping: false, maximumFractionDigits: 2 });
  }

  // , maximumFractionDigits: Math.max(getZeroesAfterDecimal(price) + 3, 2)
  return Number(price).toString();
    // .toLocaleString([], { useGrouping: false});
}

export const LimitOrderView = ({perpPositionStatus, closePositionType, existingOrder, onSave}: Props) => {
  const MAX_THRESHOLD = 100_000_000;

  const getExistingLimitPrice = () => {
    if (existingOrder) {
      const makerRaw = parseFloat(formatUnits(existingOrder.makerAmount, isLong ? token.decimals : 18));
      const takerRaw = parseFloat(formatUnits(existingOrder.takerAmount, isLong ? 18 : token.decimals));
      return (isLong ? takerRaw / makerRaw : makerRaw / takerRaw);
    }
    return undefined;
  }

  const {showInUsd, ethPrice} = useEthPrice();
  const [inputs, setInputs] = useState<LimitOrderInputs>();
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);

  const {position, token} = perpPositionStatus;
  const isLong = position.side === 'LONG';

  const isTP = closePositionType === 'tp';
  const isUsd = isUsdToken(token.address);
  const isPriceIncrease = isLong === isTP;

  const executionFee = ethPrice ? parseEther((EXECUTION_FEE_USD / ethPrice).toString()) : undefined;
  const slippage = calculateSlippage(perpPositionStatus);

  const saveOrder = useLimitOrderTransactionSign(perpPositionStatus);

  const cancelOrder = useCancelOrder()

  const getLimitPrice = () => {
    if (!inputs) {
      return undefined;
    }
    let value = parseFloat(inputs.limitPrice);
    if (isNaN(value)) {
      return undefined;
    }
    if (isUsd) {
      value = 1 / value;
    }
    return value;
  }
  const limitPrice = getLimitPrice();
  const acceptablePrice = limitPrice ? limitPrice * (isLong ? 1 / slippage : slippage) : undefined;

  const {estimatedPnL, estimatedROI, makerAmount, takerAmount, closeFee} =
    calculateAmounts(perpPositionStatus, acceptablePrice);

  const onChange = (limitPrice: string | undefined, roi: string | undefined = undefined) => {
    if (limitPrice) {
      let price = parseFloat(limitPrice);
      if (isUsd) {
        price = 1 / price;
      }
      const roi = calculateAmounts(perpPositionStatus, price * (isLong ? 1 / slippage : slippage)).estimatedROI;
      setInputs({ limitPrice, roi: roi > MAX_THRESHOLD ? `> ${MAX_THRESHOLD.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` : roi.toFixed(2) });
    } else if (roi) {
      roi = roi.replace("\%", "");
      if (parseFloat(roi) <= -85) {
        roi = "-85";
      }
      let price = calculateLimitPriceFromPnl(perpPositionStatus, parseFloat(roi));
      if (price < 0) {
        setInputs({ limitPrice: '', roi: '' });
      } else {
        setInputs({ limitPrice: convertPrice(price, isUsd), roi });
      }
    } else {
      setInputs({ limitPrice: '', roi: '' });
    }
  }

  useEffect(() => {
    let existingLimitPrice = getExistingLimitPrice();
    if (existingLimitPrice) {
      onChange(convertPrice(existingLimitPrice, isUsd));
    } else {
      onChange(undefined)
    }
  }, [closePositionType, existingOrder]);


  const handleSaveOrder = async () => {
    if (saveOrder.isPending || !executionFee) return;

    const value: ClosePositionOrder = {
      orderType: isTP ? 0 : 1,
      positionId: position.id,
      createdAt: Math.floor(new Date().getTime() / 1000),
      expiration: Math.floor(new Date().getTime() / 1000) + DAY_SECONDS * 365 * 10, // placeholder
      makerAmount: makerAmount,
      takerAmount: takerAmount,
      executionFee: executionFee,
    };

    await saveOrder.signAndSave(value);
    onSave();
  };

  const handleCancel = async () => {
    if (!existingOrder) {
      return;
    }
    await cancelOrder.signAndSave({
      positionId: position.id,
      orderType: existingOrder.orderType,
      createdAt: existingOrder.createdAt,
    });
    onSave();
  };

  const renderRoiChangeButton = (value: number, className: string) => {
    return (
      <Button
        className={twMerge("!rounded-none !text-[10px] !px-0.5 !py-0.5", className)}
        onClick={() => onChange(undefined, (value * (isTP ? 1 : -1)).toString())}
        buttonType={ButtonType.NEUTRAL}
      >
        {isTP ? "+" : "-"}{value}%
      </Button>
    )
  }

  const renderLimitOrderBody = () => {
    return (
      <div className="flex flex-col gap-2 p-4">
        <div className="standard-frame standard-stack py-2 px-4">
          <div className="flex flex-row items-center justify-between text-neutral-content text-sm">
            <div id="trigger_price" className="flex flex-row items-center gap-1">
              <div>Trigger Price</div>
              <BiHelpCircle size={16} />
            </div>
            <ReactTooltip
              anchorSelect={`#trigger_price`}
              id={`tooltip_trigger_price`}
              className="z-50 max-w-[150px]"
              content={Tooltips.LIMIT_ORDER_TRIGGER_PRICE}
              style={{backgroundColor: "#3b485f", color: "#98a2b3"}} />
            <div className="flex flex-row gap-1 cursor-pointer hover:text-white"
                 onClick={() => onChange(convertPrice(perpPositionStatus.markPrice, isUsd))}>
              <span>Current Price:</span>
              <FractionTokenPrice price={perpPositionStatus.markPrice} tokenAddress={token.address} iconSize={10} />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              {isUsd ? "$" : <FaEthereum size={16} className=""/>}
              <input
                className="unstyled-input text-xl"
                type="number"
                placeholder="0.0"
                value={
                  isUsd
                    ? (inputs?.limitPrice ? Number(inputs?.limitPrice).toLocaleString([], {maximumFractionDigits: 2, useGrouping: false}) : undefined)
                    : inputs?.limitPrice
                }
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                onChange={e => onChange(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="standard-frame standard-stack py-2 px-4">
          <div className="flex flex-row items-center justify-between text-neutral-content text-sm">
            <span className="">Estimated ROI</span>
            <div className="grid grid-cols-4">
              {renderRoiChangeButton(isTP ? 25 : 5, "!rounded-l-md")}
              {renderRoiChangeButton(isTP ? 50 : 10, "!-border-l-none")}
              {renderRoiChangeButton(isTP ? 75 : 25, "!-border-l-none")}
              {renderRoiChangeButton(isTP ? 100 : 50, "!-border-l-none !rounded-r-md")}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center max-w-1/2">
              <div className={classNames("text-xl", {
                "text-call": Number(inputs?.roi) > 0,
                "text-put": Number(inputs?.roi) < 0,
              })}>
                {Number(inputs?.roi)  > 0 ? "+" : ""}
                <input
                  style={{
                    width: `calc(${Math.min(Math.max((inputs?.roi || "0").length + 1, 2), 50)}ch)`,
                  }}
                  className={"w-full unstyled-input max-w-[200px] overflow-hidden"}
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "+") {
                      e.preventDefault();
                    }
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="0"
                  value={inputs?.roi}
                  onChange={e => onChange(undefined, e.target.value)}
                />
              </div>
              <span className="text-neutral-content">%</span>
            </div>
            {
              !inputs?.limitPrice
                ? blankValue
                : (
                  <div className="flex flex-row gap-1 items-center text-sm max-w-[200px] overflow-hidden">
                    <div className="text-nowrap	text-neutral-content">PnL: </div>
                    <EthValue
                      ethPrice={ethPrice}
                      showInUsd={showInUsd}
                      className={classNames("flex-col items-end text-right !gap-0", {
                        "text-call": estimatedROI > 0,
                        "text-put": estimatedROI < 0,
                      })}
                      showPlus={true}
                      value={parseEther(estimatedPnL.toString())}
                      // valueForPercent={parseEther(estimatedPnL.toString()) + BigInt(position.downPaymentRaw)}
                      // original={BigInt(position.downPaymentRaw)}
                      fractions={4}/>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    )
  }

  const getLimitOrderWarnings = () => {
    if (!limitPrice) return undefined;

    if (isPriceIncrease && limitPrice <= perpPositionStatus.markPrice) {
      return `Price must be ${isUsd ? "lower" : "higher"} than current price`;
    } else if (!isPriceIncrease && limitPrice >= perpPositionStatus.markPrice) {
      return `Price must be ${isUsd ? "higher" : "lower"} than current price`;
    } else if (isLong && limitPrice < perpPositionStatus.liquidationPrice) {
      return `Price must be ${isUsd ? "lower" : "higher"} than liquidation price`;
    } else if (!isLong && limitPrice > perpPositionStatus.liquidationPrice) {
      return `Price must be ${isUsd ? "higher" : "lower"} than liquidation price`;
    }

    return undefined;
  };

  const renderLimitOrderFooter = () => {
    let isEditingExistingOrder = true;
    if (existingOrder) {
      isEditingExistingOrder = getExistingLimitPrice() !== acceptablePrice;
    }

    const warning = getLimitOrderWarnings();
    const symbol = isUsd ? "ETH" : token.symbol;
    const side = isUsd ? position.side === "LONG" ? "SHORT" : "LONG" : position.side;

    return (
      <div className="standard-stack py-2">
        <div className="!cursor-pointer hover:text-white px-2"
             onClick={() => setDetailsExpanded(a => !a)}>
          <SplitLabelValue
            label={
              <div className="flex flex-row gap-2 items-center !cursor-pointer hover:text-white">
                {detailsExpanded ? <FaChevronUp/> : <FaChevronDown/>} Details
              </div>
            }
          >
            <div className="flex flex-row gap-2 items-center">
              <img src={token.imageUrl} alt={token.symbol}
                   className="w-6 h-6 rounded-full border border-glass-focus"/>
              <span className="flex flex-row gap-1">
                  <span>{symbol}</span>
                  <span>{position.leverage}x</span>
                  <span className={classNames({
                    "text-call": side === "LONG",
                    "text-put": side === "SHORT"
                  })}>{side}</span>
                </span>
            </div>
          </SplitLabelValue>
        </div>
        {
          detailsExpanded &&
          <>
            <hr className="border-neutral-content/20 w-full"/>
            <div className="px-2 standard-stack">
              <SplitLabelValue label="Opened">
                <span className="capitalize text-white">{moment.unix(position.openTimestamp).fromNow()}</span>
              </SplitLabelValue>
              <SplitLabelValue label="Entry Price" tooltip={Tooltips.CLOSE_POSITION_ENTRY_PRICE}>
                <FractionTokenPrice
                  price={position.entryPrice}
                  tokenAddress={token.address}
                  iconSize={12}
                  className="text-white"
                  exponentClassName="flex flex-row items-center text-xs text-neutral-content" />
              </SplitLabelValue>
              <SplitLabelValue
                label="Liquidation Price"
                tooltip={Tooltips.CLOSE_POSITION_LIQ_PRICE}
              >
                <FractionTokenPrice
                  price={perpPositionStatus.liquidationPrice}
                  tokenAddress={token.address}
                  iconSize={12}
                  className="text-white"
                  exponentClassName="flex flex-row items-center text-xs text-neutral-content" />
              </SplitLabelValue>
              <SplitLabelValue
                label="Total Interest Paid"
                labelClassName="text-neutral-content"
                className="flex flex-row text-white"
                tooltip={Tooltips.CLOSE_POSITION_INTEREST_PAID}
              >
                {perpPositionStatus.interestPaidEthValue &&
                  <EthValue value={perpPositionStatus.interestPaidEthValue} showInUsd={showInUsd}
                            ethPrice={ethPrice}/>}
              </SplitLabelValue>
            </div>
          </>
        }
        <hr className="border-neutral-content/20 w-full"/>
        <div className="standard-stack px-2">
          <SplitLabelValue
            label="Acceptable Price"
            labelClassName="text-neutral-content"
            className="flex flex-row text-white"
            tooltip={Tooltips.CLOSE_POSITION_EST_EXIT_PRICE}>
            {
              acceptablePrice ?
                <FractionTokenPrice
                  price={acceptablePrice}
                  tokenAddress={token.address}
                  iconSize={12}
                  exponentClassName="flex flex-row items-center text-xs text-neutral-content"/> :
                blankValue
            }
          </SplitLabelValue>
          <SplitLabelValue
            label="Execution Fee"
            labelClassName="text-neutral-content"
            className="flex flex-row text-white"
            tooltip={Tooltips.LIMIT_ORDER_EXECUTION_FEE}
          >
            {executionFee &&
              <EthValue value={executionFee} showInUsd={showInUsd} ethPrice={ethPrice}/>}
          </SplitLabelValue>
          <SplitLabelValue
            label="Close Fee"
            labelClassName="text-neutral-content"
            className="flex flex-row text-white"
            tooltip={Tooltips.CLOSE_POSITION_FEES}>
            {closeFee &&
              <EthValue value={parseEther(closeFee.toString())} showInUsd={showInUsd}
                        ethPrice={ethPrice}/>}
          </SplitLabelValue>
        </div>
        <hr className="border-neutral-content/20 w-full"/>
        <div className="standard-stack !gap-2 p-2">
          <Button
            onClick={handleSaveOrder}
            buttonType={existingOrder && !isEditingExistingOrder ? ButtonType.NEUTRAL : ButtonType.PRIMARY}
            disabled={!acceptablePrice || !isEditingExistingOrder || saveOrder.isPending || !!warning}
            loading={saveOrder.isPending}
          >
            {(existingOrder && !isEditingExistingOrder ? 'Change to Edit' : (existingOrder ? "Edit Order" : "Place Order"))}
          </Button>
          {
            existingOrder &&
            <>
              <Button onClick={handleCancel} buttonType={ButtonType.DESTRUCTIVE}>
                Cancel Order
              </Button>
              {cancelOrder.error &&
                <WarningPanel message={"Failed to cancel: " + cancelOrder.error}/>}
            </>
          }
          {saveOrder.error && <WarningPanel message={"Failed to save: " + saveOrder.error}/>}
          {warning && <WarningPanel message={warning}/>}
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderLimitOrderBody()}
      <hr className="border-neutral-content/20 w-full"/>
      {renderLimitOrderFooter()}
      {saveOrder.isSuccess && (
        <div className="flex flex-row items-center fixed bottom-[63px] right-0 typography-brand-body-l-caps min-h-[40px] px-6 py-2 [clip-path:polygon(30px_0,100%_0,100%_50%,calc(100%)_100%,0_100%,0_50%)] w-[400px] bg-[#32d581] group-focus-visible:bg-white hover:bg-white cursor-pointer text-black fadeRight">
          <FaCheckCircle className="text-white"/>
          <span className="text-white p-1 ml-2">Limit Order successfully placed...</span>
        </div>
      )}
      {cancelOrder.isSuccess && (
        <div className="flex flex-row items-center fixed bottom-[63px] right-0 typography-brand-body-l-caps min-h-[40px] px-6 py-2 [clip-path:polygon(30px_0,100%_0,100%_50%,calc(100%)_100%,0_100%,0_50%)] w-[400px] bg-[#32d581] group-focus-visible:bg-white hover:bg-white cursor-pointer text-black fadeRight">
          <FaCheckCircle className="text-white"/>
          <span className="text-white p-1 ml-2">Limit Order successfully cancelled...</span>
        </div>
      )}
    </div>
  );
}
